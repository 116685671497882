import Link from "next/link";
import { FC, Fragment } from "react";
import styles from "./Breadcrumb.module.scss";
import Text from "../Text";
import classNames from "classnames";

export interface BreadcrumbItem {
  title: string;
  path?: string;
}

interface BreadcrumbProps {
  items?: BreadcrumbItem[];
  className?: string;
}

const Breadcrumb: FC<BreadcrumbProps> = ({ items, className }) => {
  return (
    <div className={classNames(styles.breadcrumb, className, "line-clamp-2")}>
      {items?.map((item, index) => (
        <Fragment key={item.title}>
          {!item.path ? (
            <Text type={"body-2"} className={classNames({
              ["font-semibold"]: true
            })}>{item.title}</Text>
          ) : (
            <a href={item.path} className={styles.breadcrumb_item}>
              <Text className={classNames({
              ["font-semibold"]: true
            })} type={"body-2"}>{item.title}</Text>
            </a>
          )}

          {index < items.length - 1 && <Text type="body-2"> / </Text>}
        </Fragment>
      ))}
    </div>
  );
};

export default Breadcrumb;
