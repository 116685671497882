import React, { useEffect, useState } from 'react'
import styles from "./LoadingPage.module.scss"
import classNames from 'classnames'
import Icon from '../Icon'

type Props = {
    text?:string
    timeOut?:number
    onOverTime?:()=>void
}

const LoadingPage = ({text = "Loading..." , timeOut=20000 , onOverTime}: Props) => {
    
  return (
    <div className={styles.loadingPage}>
    <div className={classNames(styles.loadingContent)}> 
    {text}
         <br />
    <span className="inline-flex">
      <Icon className="animate-spin " name="status" />
      </span>
    </div>
  </div>
  )
}

export default LoadingPage