import { GetServerSideProps } from "next";
import { FC, Fragment, useEffect, useMemo, useRef, useState } from "react";

import Breadcrumb from "~/components/Breadcrumb";
import Container from "~/components/Container";
import Layout from "~/components/Layout";

import { get, isEmpty, random } from "lodash";
import { BreadcrumbJsonLd, ProductJsonLd } from "next-seo";
import { useRouter } from "next/router";
import ReactGA4 from "react-ga4";
import Divider from "~/components/Divider";
import SEOTags from "~/components/SEOTags";
import { PATH, RelatedProductCode, SectionsKey } from "~/constants/enum";
import { RECENTLY_PRODUCT } from "~/constants/localstore";
import { ProductDetailContext } from "~/contexts/product.context";
import {
  QUERY_GET_META_PRODUCT_DETAIL_BY_SLUG,
  QUERY_PRODUCT_DETAIL_BY_SKU,
} from "~/data/product/gql";
import { EnumShippingType } from "~/data/product/useGetEstimateDelivery";
import useRenderComponent from "~/hooks/useRenderComponent";
import { Product, ProductCategory, ProductVariant } from "~/services/product";
import { trackingProductView } from "~/services/tracking";
import { initializeApollo } from "~/utils/apollo-client";
import { striptTags } from "~/utils/converters";
import { getPageStruct } from "~/utils/page";
import { getTheme, getUrlCustom } from "~/utils/theme";
import { transformProductItem } from "~/utils/transform";
import ReMaketing, {
  REMARKETING_PRODUCT,
} from "~/services/tracking/ReMaketing";
import { useRecoilValue } from "recoil";
import storeConfigAtom from "~/services/store-config/store-config.atom";
interface ProductDetailProps {
  product: Product;
  slug: string;
  faqData: any;
  dataMeta: any;
  defaultVariant: ProductVariant;
}

export enum ProductDetailDataKey {
  CustomersWhoViewedThisItemAlsoViewed = "customers_who_viewed_this_item_also_viewed",
  CustomersWhoBoughtThisItemAlsoBought = "customers_who_bought_this_item_also_bought",
  ExclusiveItemsFromOurBrands = "exclusive_items_from_our_brands",
  InspiredByYourBrowsingHistory = "inspired_by_your_browsing_history",
  ProductsRelatedToThisItem = "products_related_to_this_item",
  TrendingProducts = "trending-products",
  PopularProducts = "popular-products",
}

const ProductDetail: FC<ProductDetailProps> = ({
  product,
  defaultVariant,
  dataMeta,
  slug,
}) => {
  const router = useRouter();
  // const variantId = router.query.variant as string;
  // const params = useParams()
  const pageStruct = getPageStruct(getTheme(), "product-detail");
  const [variant, setVariant] = useState<ProductVariant>(defaultVariant);
  const { availableStores } = useRecoilValue(storeConfigAtom.store);
  // const {data} = useQuery(QUERY_PRODUCT_DETAIL_BY_SKU, {
  //   variables:{
  //     param: slug
  //   }
  // })
  // console.log(data , "data");

  const [selectedShippingMethod, setSelectedShippingMethod] =
    useState<EnumShippingType>(EnumShippingType.DEFAULT);

  const [quantity, setQuantity] = useState(1);
  // const [cart, setCart] = useRecoilState(CartAtom.cart);
  // const [component, setComponent] = useState<any>();

  const sectionData = useMemo(() => {
    const products = {
      [ProductDetailDataKey.CustomersWhoViewedThisItemAlsoViewed]: {
        title: "CUSTOMERS WHO VIEWED THIS ITEM ALSO VIEWED",
        code: RelatedProductCode.CustomersWhoViewedThisItemAlsoViewed,
        sku: product?.sku,
      },
      [ProductDetailDataKey.CustomersWhoBoughtThisItemAlsoBought]: {
        title: "Customers Who Bought This Item Also Bought",
        code: RelatedProductCode.CustomersWhoBoughtThisItemAlsoBought,
        sku: product?.sku,
      },
      [ProductDetailDataKey.ExclusiveItemsFromOurBrands]: {
        title: "Exclusive Items From Our Brands",
        code: RelatedProductCode.ExclusiveItemsFromOurBrands,
        sku: product?.sku,
      },
      [ProductDetailDataKey.InspiredByYourBrowsingHistory]: {
        title: "Inspired By Your Browsing History",
        code: RelatedProductCode.InspiredByYourBrowsingHistory,
        sku: product?.sku,
      },
      [ProductDetailDataKey.ProductsRelatedToThisItem]: {
        title: "Products Related To This Item",
        code: RelatedProductCode.ProductsRelatedToThisItem,
        sku: product?.sku,
      },
    };
    return products;
  }, [product?.sku]);

  const { render } = useRenderComponent({
    sectionData: sectionData,
    // fileName:
    //   Number(product.sku) % 2 === 0
    //     ? SectionsKey.ProductDetailTwo
    //     : SectionsKey.ProductDetail,
    fileName: SectionsKey.ProductDetail,
  });

  const setRecentlyProduct = (SKU: any) => {
    if (typeof window != "undefined") {
      const listRecently = localStorage.getItem(RECENTLY_PRODUCT);
      if (!!listRecently) {
        const parseListRecently = JSON.parse(listRecently) || [];
        if (Array.isArray(parseListRecently)) {
          const index = parseListRecently.findIndex((i) => i === SKU);
          if (index !== -1) {
            parseListRecently.splice(index, 1);
          }
          parseListRecently.unshift(SKU?.toString());
          const listResult = parseListRecently.slice(0, 10);
          localStorage.setItem(RECENTLY_PRODUCT, JSON.stringify(listResult));
        }
      } else {
        const tempList = [SKU.toString()];
        localStorage.setItem(RECENTLY_PRODUCT, JSON.stringify(tempList));
      }
    }
  };

  useEffect(() => {
    setQuantity(1);
    setVariant(defaultVariant);
  }, [slug]);

  useEffect(() => {
    if (!product?.sku) return;
    setRecentlyProduct(product?.sku);
  }, [product?.sku]);

  const breadcrumbData = useMemo(() => {
    if (isEmpty(product?.categories) || product?.categories?.length === 0)
      return [{ title: "Home", path: "/" }];
    // const categories = product?.categories[product?.categories.length - 1];
    //get maximum level of category
    const maxLevel: any = product?.categories.reduce(
      (max: number = 0, c: ProductCategory) => {
        return c.level > max ? c.level : max;
      },
      0
    );
    const categories = product?.categories?.find(
      (c: ProductCategory) => c.level === maxLevel
    );

    return [{ title: "Home", path: "/" }].concat(
      categories?.breadcrumbs?.map((c: any) => {
        const path = categories.url_path?.split(c.category_url_key)[0];

        return {
          title: c.category_name,
          path: PATH.CategoryDetail.replace(
            "[slug]",
            path + c.category_url_key
          ),
        };
      }) || []
    );
    // return [
    //   { title: "Home", path: "/" },
    //   {
    //     title: categories.name,
    //     path: "/category/" + categories.url_key,
    //     active: true,
    //   },
    //   // { title: "T-Shirt", path: "/category/123" },
    //   // {
    //   //   title: product.name,
    //   // },
    // ];
  }, []);

  const ProductMeta = useMemo(() => {
    return {
      title: dataMeta?.meta_title || dataMeta?.name,
      description:
        dataMeta?.meta_description ||
        striptTags(dataMeta?.description?.html) ||
        dataMeta?.name,
      keywords: dataMeta?.meta_keyword || dataMeta?.name,
      image: dataMeta?.thumbnail?.url,
      url: dataMeta?.url_key,
      type: "product",
    };
  }, [dataMeta]);

  let linkDefaultVariant = getUrlCustom({
    pathname: process.env.NEXT_PUBLIC_URL + PATH.ProductDetail,
    query: {
      slug: product?.url_key,
      variant: product?.variants?.[0]?.product?.id,
    },
  });

  const initViewItem = useRef(false);
  useEffect(() => {
    if (
      !isEmpty(product) &&
      !isEmpty(variant) &&
      !initViewItem.current &&
      !!ReactGA4._hasLoadedGA
    ) {
      trackingProductView({
        variant,
        product,
        qty: quantity,
      });
      initViewItem.current = true;
    }
  }, [product, variant, ReactGA4._hasLoadedGA]);

  return (
    <Fragment>
      <ProductDetailContext.Provider
        value={{
          product,
          selectedShippingMethod,
          setSelectedShippingMethod,
          variant,
          setVariant,
          quantity,
          setQuantity,
        }}
      >
        <SEOTags {...ProductMeta}>
          <link rel="canonical" href={linkDefaultVariant} data-vm-ssr="true" />
          <meta property="og:image:width" content="600" />
          <meta property="og:image:height" content="600" />
          <meta
            property="product:price:amount"
            content={
              defaultVariant?.product?.price_range?.minimum_price?.regular_price
                ?.value + "" || ""
            }
          />
          <meta
            property="product:price:currency"
            content={
              defaultVariant?.product?.price_range?.minimum_price?.regular_price
                ?.currency || "USD"
            }
          />
          <meta property="og:availability" content="instock" />
        </SEOTags>
        {
          <ReMaketing
            data={{
              price:
                variant?.product?.price_range?.minimum_price?.final_price
                  ?.value,
              sku: variant?.product?.sku,
            }}
            gtagType={REMARKETING_PRODUCT}
          />
        }
        {/* schema */}
        <>
          <BreadcrumbJsonLd
            itemListElements={breadcrumbData?.map((item, index) => {
              return {
                position: index + 1,
                name: item.title,
                item: `${process.env.NEXT_PUBLIC_URL}${item.path}`,
              };
            })}
          />
          <ProductJsonLd
            productName={ProductMeta?.title}
            images={product.images}
            sku={product.sku}
            aggregateRating={
              !!product.rating_summary
                ? {
                    ratingValue: product.rating_summary || 0,
                    reviewCount: product.review_count || 0,
                  }
                : undefined
            }
            description={ProductMeta?.description}
            brand={process.env.NEXT_PUBLIC_SEO_DEFAULT_TITLE}
            offers={product.variants?.map((item: ProductVariant) => {
              return {
                price: get(
                  item,
                  "product.price_range.minimum_price.regular_price.value",
                  0
                ),
                priceCurrency: get(
                  item,
                  "product.price_range.minimum_price.regular_price.currency",
                  "USD"
                ),
                availability: "https://schema.org/InStock",
                itemCondition: "https://schema.org/NewCondition",
                url: `${
                  process.env.NEXT_PUBLIC_URL
                }${PATH.ProductDetail.replace("[slug]", slug)}?variant=${
                  item.product.id
                }`,
              };
            })}
          />
        </>
        <Layout isUseLayout className="lg:pt-3">
          <Container className="lg:block hidden ">
            <Breadcrumb items={breadcrumbData} />
          </Container>
          <div className="flex flex-col min-h-screen">{render()}</div>
          <Divider />
          <Container className="block lg:hidden text-center py-3">
            <Breadcrumb items={breadcrumbData} />
          </Container>
        </Layout>
      </ProductDetailContext.Provider>
    </Fragment>
  );
};

export default ProductDetail;

const ssrCache = new Map();
export const getServerSideProps: GetServerSideProps = async ({
  params,
  res,
  query,
  req,
}) => {
  // const cacheKey = req?.url;
  // if (ssrCache.has(cacheKey)) {
  //   return ssrCache.get(cacheKey);
  // }
  const apolloClient = initializeApollo({
    httpLinkProps: { useGETForQueries: true },
  });
  let queryVariant = query?.variant || "";
  let faqData = null;
  const url_key = params?.slug;

  const { data } = await apolloClient.query({
    query: QUERY_GET_META_PRODUCT_DETAIL_BY_SLUG,
    variables: {
      param: params?.slug,
    },
  });
  // const { data } = await apolloClient.query({
  //   query: QUERY_GET_PRODUCT_DETAIL_SSR,
  //   variables: {
  //     param: params?.slug,
  //   },
  // });

  if (data?.products?.items?.length === 0) {
    const slug: string = (params?.slug as string) || "";

    // const arrURL = slug.split('-');
    // const defaultIndex = arrURL?.length || 1;
    // let SKU = arrURL[defaultIndex];
    const regex = /\b(\d{12})(-p)?\b/;
    const matching: any = slug.match(regex);

    const SKU = matching?.[1];

    // for (let index = defaultIndex - 1; index >= 0; index--) {
    //   if (regex.test(arrURL[index])) {
    //     if (index != arrURL?.length) {
    //       SKU = arrURL[index] + (arrURL[index + 1] ? `-${arrURL[index + 1]}` : '');
    //       break;
    //     }
    //     SKU = arrURL[index];
    //     break;
    //   }
    // }
    if (!!SKU) {
      const { data: dataSKU } = await apolloClient.query({
        query: QUERY_PRODUCT_DETAIL_BY_SKU,
        variables: {
          param: SKU,
        },
      });
      if (res && dataSKU?.products?.items?.length > 0) {
        const { products } = dataSKU;
        const correctURL = products?.items[0].url_key;
        const querystring = require("querystring");
        const tempQuery = { ...query };
        delete tempQuery["id"];
        let newQuery = querystring.stringify(tempQuery);
        if (!!newQuery) {
          newQuery = "?" + newQuery;
        }
        res.writeHead(301, {
          Location: `${PATH.ProductDetail.replace(
            "[slug]",
            correctURL
          )}${newQuery}`,
        });
        res.end();
      }
    } else {
      return {
        notFound: true,
      };
    }
  } else {
    const newProduct = data?.products?.items[0];
    const variants = get(newProduct, "variants", "");
    const isCorrectVariant = variants?.find(
      (item: any) => item?.product.id + "" === query?.variant
    );

    if (!isCorrectVariant) {
      queryVariant = get(newProduct, "variants[0].product.id", "");
      const querystring = require("querystring");
      const tempQuery = { ...query };
      tempQuery["variant"] = queryVariant;
      delete tempQuery["slug"];
      let newQuery = querystring.stringify(tempQuery);
      if (!!newQuery) {
        newQuery = "?" + newQuery;
      }
      return {
        redirect: {
          permanent: true,
          destination: `/products/${url_key}${newQuery}`,
        },
      };
    }
  }

  // const { data: dataProductQuestion } = await apolloClient.query({
  //   query: QUERY_PRODUCT_QUESTIONS_SSR,
  //   variables: {
  //     param: params?.slug,
  //   },
  // });

  faqData = get(data, "products.items[0].productQuestions", "");

  const defaultVariant =
    data?.products?.items?.[0]?.variants.find(
      (item: ProductVariant) => item.product.id + "" === queryVariant
    ) || null;

  if (!data?.products?.items?.length) {
    return {
      notFound: true,
    };
  }

  const pageProps = {
    props: {
      product: transformProductItem(data?.products?.items?.[0]),
      slug: params?.slug,
      faqData,
      dataMeta: data?.products?.items?.[0] || {},
      defaultVariant,
    },
  };
  // ssrCache.set(cacheKey, pageProps);
  // setTimeout(() => ssrCache.delete(cacheKey), 1000 * 60 * 5);

  return pageProps;
};
