import { SectionsKey } from "~/constants/enum";

const PageStruct = {
  id: "cart",
  useLayout: true,
  children: [
    { id: "cart", order: 1 },
    {
      id: SectionsKey.RecentlyView,
      order: 2,
      dataKey: SectionsKey.RecentlyView,
    },
  ],
};

export default PageStruct;
