import React, { createElement, useCallback, useEffect, useMemo, useState } from 'react';
import { getPageStruct, renderPage } from '~/utils/page';
import { getTheme } from '~/utils/theme';

type Props<T> = {
    sectionData?: {
        [x: string]: T
    }
    fileName: string
}

export const useRenderComponent = <T extends unknown>(props: Props<T>) => {
    const { sectionData, fileName } = props
    const pageStruct = useMemo(() => getPageStruct(getTheme(), fileName), [fileName]);

    const [component, setComponent] = useState<React.ReactElement[]>();

    useEffect(() => {
        async function fetchComponent() {
            const component = await renderPage(
                pageStruct.children.sort((a: any, b: any) => a.order - b.order),
                getTheme()
            );
            // setComponent(component.filter((c) => !!c)); before version
            setComponent(component)
        }

        fetchComponent();
    }, [pageStruct?.children])
    
    const renderComponents = useCallback(() => {
        if(!component?.length) {
            return 
        }
        return component?.map((com, index: number) => {
            if (!com) return null

            const { type, key, props } = com || {}
            const dataKey = pageStruct.children?.[index]?.dataKey
            const dataProps = sectionData?.[dataKey] || {}
            const newProps = { ...props, ...dataProps }

            return {
                ...com,
                props: {
                    ...com.props,
                    ...newProps
                 }
            }
        });
    }, [component, sectionData, pageStruct?.children])

    return {
        render: renderComponents
    }
}

export default useRenderComponent